import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchField from "../../forms/search-field";

function LibrarySearch(props) {
  const { isAuthenticated, searchLibraries } = props;
  const navigate = useNavigate();
  const { libraryId } = useParams();
  const authRef = useRef(false);

  // trigger search when landing on the search result page
  useEffect(() => {
    if (isAuthenticated && !authRef.current && libraryId) {
      authRef.current = isAuthenticated;
      searchLibraries({ libraryId });
    }
  }, [isAuthenticated, libraryId, searchLibraries]);

  const handleSearch = libraryId => {
    let trimmedLibraryId = libraryId.trim();
    if (!trimmedLibraryId.length || trimmedLibraryId.length < 2) {
      return;
    }
    searchLibraries({ libraryId: trimmedLibraryId });
    navigate(`/libraries/${trimmedLibraryId}`);
  };

  return (
    <SearchField
      onSubmit={handleSearch}
      search={libraryId}
      placeholder="Search by library ID"
    />
  );
}

export default LibrarySearch;
