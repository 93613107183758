import React, { useState } from "react";
import { connect } from "react-redux";
import { BOOKCREATOR_APP_URL, SUBSCRIPTIONS_DOMAIN } from "../../../config";
import { notificationsActions } from "../../../core/notifications";
import Button from "../../components/button";
import Content from "../../components/content";
import Dropdown from "../../components/forms/dropdown";
import TextBox from "../../components/forms/text-box";
import Header from "../../components/header";
import "./index.scss";

const coupons = [
  { code: "CAMPUSTORE12", expiry: { months: 6 } },
  { code: "CAMPUSTORE36", expiry: { months: 6 } },
  { code: "CAMPUSTORE60", expiry: { months: 6 } },
  { code: "TEKVILLE12", expiry: { months: 6 } },
  { code: "TEKVILLE24", expiry: { months: 6 } },
];
const couponOptions = coupons.map(c => ({ label: c.code, value: c.code }));

function Campustore(props) {
  const { addErrorMessage } = props;
  const [coupon, setCoupon] = useState(coupons[0]);
  const [number, setNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleNumberBlur = () => {
    setNumber(Math.min(Math.max(number, 1), 100));
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    setLoading(true);
    setResult(null);
    const { code, expiry } = coupon;
    const response = await fetch(
      `${SUBSCRIPTIONS_DOMAIN}/admin/coupons/${encodeURIComponent(code)}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ count: number, expiry }),
      },
    );
    setLoading(false);
    if (response.status === 200) {
      const result = await response.json();
      setResult(result);
    } else {
      addErrorMessage("Failed to generate codes");
    }
  };

  const handleChange = c => {
    const coupon = coupons.find(coupon => coupon.code === c.value);
    setCoupon(coupon);
  };

  const downloadCSV = () => {
    const csv = `Code, URL\n${result
      .map(r => {
        const link = `${BOOKCREATOR_APP_URL}/upgrade/${r}`;
        return `${r},${link}`;
      })
      .join("\n")}`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "coupons.csv";
    a.click();
  };

  return (
    <>
      <Header title="Campustore" />
      <Content>
        <div className="campustore">
          <form className="campustore__form" onSubmit={handleSubmit}>
            <Dropdown
              label="Coupon"
              options={couponOptions}
              value={couponOptions.find(c => c.value === coupon.code)}
              onChange={c => handleChange(c)}
            />
            <TextBox
              label="Number"
              labelHelper="(max 100)"
              type="number"
              value={number}
              min={1}
              max={100}
              onChange={setNumber}
              onBlur={handleNumberBlur}
            />
            <Button type="submit" loading={loading} disabled={loading}>
              Generate
            </Button>
          </form>
          {result ? (
            <div className="campustore__results">
              <button
                className="campustore__results__download"
                onClick={downloadCSV}
                type="button">
                Download CSV
              </button>
              <table>
                <tbody>
                  {result.map(r => {
                    const link = `${BOOKCREATOR_APP_URL}/upgrade/${r}`;
                    return (
                      <tr key={r}>
                        <td>
                          <code>{r}</code>
                        </td>
                        <td>
                          <span>{link}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </Content>
    </>
  );
}

const mapDispatchToProps = {
  ...notificationsActions,
};

export default connect(null, mapDispatchToProps)(Campustore);
