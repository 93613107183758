import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import SettingsIcon from "../../../../images/svg/settings.svg";
import MiniMenu from "../../../components/ui/mini-menu";
import SvgIcon from "../../../components/ui/svg-icon";
import "./index.scss";

const AnalyticsSettings = ({ compact, textonly, onChange, hiddenCharts }) => {
  const [open, setOpen] = useState(false);
  const _isMounted = useRef(true);
  const _container = useRef(null);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const handleToggleClick = e => {
    setOpen(prevOpen => !prevOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  const closeMenu = () => {
    if (_isMounted.current) {
      setOpen(false);
    }
  };

  const getPosition = () => {
    return {
      x: _container.current.offsetLeft,
      y: _container.current.offsetTop,
    };
  };

  const handleLabelClick = e => {
    e.stopPropagation();
  };

  const options = [
    {
      name: "Teachers",
      key: "teacherCount",
    },
    {
      name: "Students",
      key: "studentCount",
    },
    {
      name: "Libraries created",
      key: "libraryCount",
    },
    {
      name: "Books created",
      key: "bookCount",
    },
    {
      name: "Active users",
      key: "activeUsers",
    },
    {
      name: "Time using Book Creator",
      key: "totalSessionTime",
    },
    {
      name: "Total books created",
      key: "historicBookCount",
    },
    {
      name: "Sessions by OS",
      key: "sessionsByOS",
    },
    {
      name: "Logins by provider",
      key: "loginsByProvider",
    },
  ];

  const containerClass = classNames("analytics-settings", {
    "analytics-settings--open": open,
  });

  return (
    <div ref={_container} className={containerClass}>
      <button
        onClick={handleToggleClick}
        className="analytics-settings__toggle">
        <SvgIcon icon={SettingsIcon} />
      </button>
      {open && (
        <MiniMenu
          compact={compact}
          textonly={textonly}
          onChange={onChange}
          hiddenCharts={hiddenCharts}
          closeMenu={closeMenu}
          getPosition={getPosition}>
          <div className="analytics-settings__items">
            {options.map(option => {
              const { key, name } = option;
              const hidden = hiddenCharts.includes(key);
              return (
                <label
                  onClick={handleLabelClick}
                  key={key}
                  className="analytics-settings__item">
                  <input
                    type="checkbox"
                    checked={!hidden}
                    onChange={e => onChange(key)}
                  />
                  {name}
                </label>
              );
            })}
          </div>
        </MiniMenu>
      )}
    </div>
  );
};

export default AnalyticsSettings;
