import { Record } from "immutable";

import {
  DOWNGRADE_ATTEMPT,
  DOWNGRADE_SUCCESS,
  DOWNGRADE_ERROR,
  SET_DOWNGRADING_ERROR,
  CLEAR_DOWNGRADING_SUCCESS,
  SET_SHOW_DOWNGRADE_MODAL,
  SET_USER_TO_DOWNGRADE,
  SET_USER_REQUIRE_SSO,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

export const DowngradeState = new Record({
  downgrading: false,
  downgradingError: null,
  downgradingSuccess: null,
  showDowngradeModal: false,
  userToDowngrade: null,
  requireSsoLoginProvider: false,
});

export function downgradeReducer(
  state = new DowngradeState(),
  { payload, type }
) {
  switch (type) {
    case DOWNGRADE_ATTEMPT:
      return state.merge({ downgrading: true });
    case DOWNGRADE_SUCCESS:
      return state.merge({
        downgrading: false,
        downgradingSuccess: true,
        requireSsoLoginProvider: false,
      });
    case DOWNGRADE_ERROR:
      return state.merge({
        downgrading: false,
        downgradingError: payload,
      });
    case SET_SHOW_DOWNGRADE_MODAL:
      return state.merge({
        showDowngradeModal: payload,
        userToDowngrade: payload ? state.userToDowngrade : null,
        requireSsoLoginProvider: payload
          ? state.requireSsoLoginProvider
          : false,
        downgradingError: payload ? state.downgradingError : null,
        downgradingSuccess: null,
      });
    case SET_USER_TO_DOWNGRADE:
      return state.merge({
        userToDowngrade: payload,
      });
    case SIGN_OUT_SUCCESS:
      return new DowngradeState();
    case SET_USER_REQUIRE_SSO:
      return state.merge({ requireSsoLoginProvider: payload });
    case SET_DOWNGRADING_ERROR:
      return state.merge({ downgradingError: payload });
    case CLEAR_DOWNGRADING_SUCCESS:
      return state.merge({ downgradingSuccess: null });
    default:
      return state;
  }
}
