export function getDowngrade(state) {
  return state.downgrade;
}

export function getIsDowngrading(state) {
  return getDowngrade(state).downgrading;
}

export function getDowngradingError(state) {
  return getDowngrade(state).downgradingError;
}

export function getDowngradingSuccess(state) {
  return getDowngrade(state).downgradingSuccess;
}

export function getShowDowngradeModal(state) {
  return getDowngrade(state).showDowngradeModal;
}

export function getUserToDowngrade(state) {
  return getDowngrade(state).userToDowngrade;
}

export function getUserRequiresSSOLogin(state) {
  return getDowngrade(state).requireSsoLoginProvider;
}
