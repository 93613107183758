import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getIsDowngrading,
  getDowngradingError,
  getDowngradingSuccess,
  downgradeActions,
  getUserToDowngrade,
  getUserRequiresSSOLogin,
} from "../../../core/downgrade";
import { getHasEngineerPrivileges } from "../../../core/auth";
import DowngradeForm from "./form";
import Modal from "../modal";

function DowngradeModal(props) {

  const handleCancelClick = (e) => {
    const { setShowDowngradeModal, setUserToDowngrade } = props;
    setShowDowngradeModal(false);
    setUserToDowngrade(null);
  };

  const {
    isDowngrading,
    doDowngrade,
    downgradingError,
    downgradingSuccess,
    setDowngradingError,
    clearDowngradingSuccess,
    userToDowngrade,
    userRequiresSSOLogin,
    hasEngineerPrivileges,
  } = props;

  return (
    <Modal
      modal
      hideFooter
      showX={downgradingSuccess}
      onCancel={handleCancelClick}
      bodyStyle={{ background: "transparent", padding: 0 }}
    >
      <DowngradeForm
        isDowngrading={isDowngrading}
        doDowngrade={doDowngrade}
        downgradingError={downgradingError}
        userToDowngrade={userToDowngrade}
        hasEngineerPrivileges={hasEngineerPrivileges}
        userRequiresSSOLogin={userRequiresSSOLogin}
        setDowngradingError={setDowngradingError}
        downgradingSuccess={downgradingSuccess}
        onCancelClick={!isDowngrading ? handleCancelClick : null}
        clearDowngradingSuccess={clearDowngradingSuccess}
      />
    </Modal>
  );
}

const mapStateToProps = createSelector(
  getIsDowngrading,
  getDowngradingError,
  getDowngradingSuccess,
  getUserToDowngrade,
  getUserRequiresSSOLogin,
  getHasEngineerPrivileges,
  (
    isDowngrading,
    downgradingError,
    downgradingSuccess,
    userToDowngrade,
    userRequiresSSOLogin,
    hasEngineerPrivileges
  ) => ({
    isDowngrading,
    downgradingError,
    downgradingSuccess,
    userToDowngrade,
    userRequiresSSOLogin,
    hasEngineerPrivileges,
  })
);

const mapDispatchToProps = {
  ...downgradeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DowngradeModal);
