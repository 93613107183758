import React, { useState } from "react";
import Form from "../../forms/form";
import FlatButton from "../../button/flat";
import Success from "./success";
import Message from "../../ui/message";
import Checkbox from "../../forms/checkbox";
import "./index.scss";

function DowngradeForm(props) {
  const {
    doDowngrade,
    userToDowngrade,
    userRequiresSSOLogin,
    hasEngineerPrivileges,
    isDowngrading,
    downgradingError,
    downgradingSuccess,
    clearDowngradingSuccess,
    onCancelClick,
  } = props;

  const [blockUpgrade, setBlockUpgrade] = useState(true);
  const [newStudentLoginProvider, setNewStudentLoginProvider] = useState(null);

  const STUDENT_LOGIN_PROVIDERS = {
    "Google": "google:com",
    "Office 365": "office365",
  };

  const handleSubmit = evt => {
    doDowngrade(userToDowngrade, blockUpgrade, newStudentLoginProvider);
    evt.preventDefault();
  };

  const handleLabelClick = evt => {
    setBlockUpgrade(!blockUpgrade);
    evt.preventDefault();
  };

  const enableSelectProvider = hasEngineerPrivileges && userRequiresSSOLogin;

  return (
    <div className="downgrade-form">
      <Form
        header="Downgrade teacher account to student"
        width={500}
        onSubmit={handleSubmit}>
        {downgradingSuccess ? (
          <Success onClose={clearDowngradingSuccess} />
        ) : null}
        <div className="form__content">
          {downgradingError ? (
            <Message type="error">{downgradingError}</Message>
          ) : null}
          <>
            <label
              className="downgrade-form__checkbox"
              onClick={handleLabelClick}>
              <Checkbox checked={blockUpgrade} /> Lock to always be a student
            </label>
            {enableSelectProvider ? (
              <>
                <h4>Select new student login provider</h4>
                <div className="downgrade-form__provider">
                  {Object.entries(STUDENT_LOGIN_PROVIDERS).map(([label, key]) => (
                    <label
                      className="downgrade-form__checkbox"
                      onClick={() => setNewStudentLoginProvider(key)}>
                      <Checkbox checked={newStudentLoginProvider === key} />
                      {label}
                    </label>
                  ))}
                </div>
              </>
            ) : null}
            <div className="downgrade-form__submit">
              {onCancelClick ? (
                <FlatButton type="negative" onClick={onCancelClick}>
                  Cancel
                </FlatButton>
              ) : null}
              <FlatButton
                type="submit"
                disabled={
                  isDowngrading || (enableSelectProvider && !newStudentLoginProvider)
                }
                loading={isDowngrading}>
                Downgrade
              </FlatButton>
            </div>
          </>
        </div>
      </Form>
    </div>
  );
}

export default DowngradeForm;
