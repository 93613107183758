export const DOWNGRADE_ATTEMPT = "DOWNGRADE_ATTEMPT";
export const DOWNGRADE_SUCCESS = "DOWNGRADE_SUCCESS";
export const DOWNGRADE_ERROR = "DOWNGRADE_ERROR";

export const SET_DOWNGRADING_ERROR = "SET_DOWNGRADING_ERROR";
export const CLEAR_DOWNGRADING_SUCCESS = "CLEAR_DOWNGRADING_SUCCESS";

export const SET_SHOW_DOWNGRADE_MODAL = "SET_SHOW_DOWNGRADE_MODAL";
export const SET_USER_TO_DOWNGRADE = "SET_USER_TO_DOWNGRADE";
export const SET_USER_REQUIRE_SSO = "SET_USER_REQUIRE_SSO";
