export const GET_ORGANISATIONS_RESULT = "GET_ORGANISATIONS_RESULT";
export const GET_DELETED_ORGANISATIONS_RESULT = "GET_DELETED_ORGANISATIONS_RESULT";
export const SET_SORT_BY = "SET_SORT_BY";
export const RESET_ORGANISATIONS = "RESET_ORGANISATIONS";

export const ORG_ADDED = "ORG_ADDED";
export const ORG_CHANGED = "ORG_CHANGED";
export const ORG_REMOVED = "ORG_REMOVED";

export const ADD_ORG_SUCCESS = "ADD_ORG_SUCCESS";
export const SET_ORG_SAVING = "SET_ORG_SAVING";
export const SET_NEW_ORG_ERROR = "SET_NEW_ORG_ERROR";
export const SET_EXISTING_TEACHER_LICENSE_ERROR =
  "SET_EXISTING_TEACHER_LICENSE_ERROR";

export const SET_DOMAIN_LICENSE_RETRY = "SET_DOMAIN_LICENSE_RETRY";
export const SET_DOMAIN_CONFIRMATIONS = "SET_DOMAIN_CONFIRMATIONS";
