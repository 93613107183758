import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { getAuthToken } from "../../../../core/auth";
import {
  getDomainSearching,
  getUsersSearching,
  usersActions,
} from "../../../../core/users";
import SearchField from "../../forms/search-field";

const getSearchDomain = location => {
  const query = location.search;
  if (!query) {
    return null;
  }
  const params = new URLSearchParams(location.search);
  return params.get("domain");
};

function UserSearch(props) {
  let { userIdOrEmail } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { searchUsers, resetUserState, searchByDomain, authToken } = props;

  useEffect(() => {
    if (authToken) {
      if (!userIdOrEmail) {
        resetUserState();
      }
      const domainSearch = getSearchDomain(location);
      if (domainSearch) {
        searchByDomain({ searchTerm: domainSearch, newSearch: true });
      } else if (userIdOrEmail) {
        searchUsers({ searchTerm: userIdOrEmail });
      }
    }
  }, [
    userIdOrEmail,
    resetUserState,
    searchUsers,
    searchByDomain,
    location,
    authToken,
  ]);

  const handleSearch = searchTerm => {
    let trimmedSearch = searchTerm.trim();
    if (!trimmedSearch.length || trimmedSearch.length < 2) {
      return;
    }

    if (trimmedSearch[0] === "@") {
      navigate(`/users/?domain=${trimmedSearch}`);
    } else {
      navigate(`/users/${trimmedSearch}`);
    }
  };

  return (
    <SearchField
      onSubmit={handleSearch}
      search={userIdOrEmail || getSearchDomain(location)}
      placeholder="Search users by email or ID"
    />
  );
}

const mapStateToProps = createSelector(
  getAuthToken,
  getUsersSearching,
  getDomainSearching,
  (authToken, usersSearching, domainSearching) => ({
    authToken,
    usersSearching,
    domainSearching,
  }),
);

const mapDispatchToProps = {
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
