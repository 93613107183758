import React, { useEffect, useState } from "react";
import moment from "moment";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import Header, { HeaderActions } from "../../header";
import { Link } from "react-router-dom";
import FlatButton from "../../button/flat";
import Content from "../../content";
import Table from "../../table";
import Licenses from "../organisations-table/licenses";
import { List } from "immutable";
import { debounce } from "../../../../utils";
import { getHasEngineerPrivileges } from "../../../../core/auth";
import {
  organisationsActions,
  getDeletedOrganisations,
  getSortBy,
  getTotalDeletedCount,
} from "../../../../core/organisations";

const DATE_FORMAT = "Do MMM YYYY";

function DeletedOrganisations(props) {
  const {
    organisations,
    totalCount,
    getDeletedOrganisationsPagination,
    hasEngineerPrivileges
  } = props;

  const tableData = {
    headings: [
      {
        name: "Name",
      },
      {
        name: "Start Date",
      },
      {
        name: "End Date",
      },
      {
        name: "Closed Date",
      },
      {
        name: "Deal amount",
      },
      {
        name: "Licenses",
      },
      (hasEngineerPrivileges && {
        name: "Domain Matchers"
      })
    ],
    body: null,
  };


  const [sortBy, setSortBy] = useState("deleted_at.DESC");
  const localPageSize = parseInt(localStorage.getItem("tablePageSize"), 10);
  const [pageSize, setPageSize] = useState(localPageSize || 10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  // debounce the search a bit to avoid multiple requests per keystroke
  useEffect(() => {
    const trimmedSearch = search.trim().toLowerCase();
    debounce(setDebouncedSearch, 500)(trimmedSearch);
  }, [search]);

  useEffect(() => {
    getDeletedOrganisationsPagination(
        pageSize,
        currentPage * pageSize,
        sortBy,
        debouncedSearch
      );
    }, 
  [
  getDeletedOrganisationsPagination,
  pageSize,
  currentPage,
  sortBy,
  debouncedSearch
  ]);

  const getOrganisationRows = () => {
    return new List(
      organisations.map(org => {
        const {
          dealAmount,
          id,
          name,
          startDate,
          endDate,
          licenses,
          deletedAt,
          deletedBy,
          domainMatchers,
        } = org.toJS();
        return {
          ...org,
          selected: false,
          rowId: id,
          cells: [
            <span>{name}</span>,
            <span>
              {startDate ? moment(startDate).format(DATE_FORMAT) : null}
            </span>,
            <span>{endDate ? moment(endDate).format(DATE_FORMAT) : null}</span>,
            <span>
              {deletedAt ? (
                <div>
                  {`${moment(deletedAt).format(DATE_FORMAT)} by`}
                  <Link
                    to={`/users/${deletedBy}`}
                    style={{ display: "block", fontSize: 12 }}>
                    {deletedBy}
                  </Link>
                </div>
              ) : null}
            </span>,
            <span>
              {dealAmount
                ? dealAmount
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                    .split(".")[0]
                : `$0`}
            </span>,
            <Licenses licenses={licenses} />,
            ...(hasEngineerPrivileges
              ? [
                <span>{domainMatchers.length ? domainMatchers.map(dm => dm.domain).join(",") : null}</span>,
                ]
              : []),
          ],
        };
      })
    );
  };

  const td = {
    ...tableData,
    body: getOrganisationRows(),
  };

  const showSearch = hasEngineerPrivileges ? {
    search,
    setSearch
  } : null

  return (
    <div>
      <Header title="Deleted Organisations">
        <HeaderActions>
          <FlatButton link="/organisations">Back</FlatButton>
        </HeaderActions>
      </Header>
      <Content>
        <Table
          {...showSearch}
          data={td}
          sortBy={sortBy}
          rowHeight={58}
          onHeadingClick={setSortBy}
          externalPagination={{
            pageSize,
            onPageChange: setCurrentPage,
            onPageSizeChange: size => {
              setPageSize(size);
              setCurrentPage(0);
            },
            totalCount,
          }}
        /> 
      </Content>
    </div>
  );
}

const mapStateToProps = createSelector(
  getDeletedOrganisations,
  getSortBy,
  getTotalDeletedCount,
  getHasEngineerPrivileges,
  (organisations, sortBy, totalCount, hasEngineerPrivileges ) => ({
    organisations,
    sortBy,
    totalCount,
    hasEngineerPrivileges
  })
);

const mapDispatchToProps = {
  ...organisationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedOrganisations);
